function About() {
    return (
        <div className="container">
            <div className="w-100 p-5">
                <h1 className="text-center">關於大會</h1>
            </div>
            <hr></hr>
            <div>
                <h4 className="text-center"> 會議的緣起與目的 </h4>
            </div>
            <hr></hr>
            <div>
                <p>資訊安全與軟體系統可靠度的發展對於社會安定及國家安全具相當核心的關鍵，在國際潮流走向高度資訊化的新興年代，人們的生活也因此深度依賴資訊科技營造的環境。然而這樣的環境是否安全可靠？是否能維護隱私及個人權益？仍是非常值得關注的議題。在過去學術界對於密碼技術、系統及網路安全均十分的重視，然而較為欠缺的如軟體設計安全、韌體方面安全的考量以及其他安全實務，近年來也逐漸受到青睞。原因是資訊安全問題不再是單獨的一項技術探討，而是必須結合其他軟硬體、作業系統共同研究，並納入工商業界實務的安全問題，才能全面地掌握。</p>
                <p>因此，舉辦此會議旨在要提供一個會議的平臺，匯集國內在理論與實務具備經驗的學者及專家以共同探討，相互交流，以激盪出豐富的研發成果。我國在資訊技術無論是軟體或硬體都具備相當高的水準，近年來在資訊安全、駭客攻防技術上的發展亦頗有成效。因此會議的舉辦能將過去國內相關技術與優勢，透過會議的相互交流了解，更能走向系統整合、提升安全及可靠度的高技術水準。也期望透過這次的會議，在學術尖端的專家學者與具實務經驗之業者共同參與，帶給國內更為多安全整合與系統及網路之技術能量的提升。</p>
                <p>全國資訊安全會議為中華民國資訊安全學會主辦，每年定期舉行，迄今已累計舉辦 33 屆，是我國重要的資訊安全學術活動之一，每屆會議參與人數約有數百餘人參加。該會議將邀請資訊安全相關領域之產、官、學界的學者及專家們共同參與，會議中除論文發表外，並提供技術交流、心得分享、成果發佈以及經驗傳承。藉由會議可促進學術單位、政府單位及產業界在資訊安全研究相關成果，預期將可提升我國資訊安全研究能量。</p>
                <p>第 34 屆全國資訊安全會議將由國立臺北科技大學及中華民國資訊安全學會共同舉辦，依循過往方式，將邀請學術界學者、產業界相關人士、公私立研究機構之研究人員以及資訊安全相關專家進行論文發表，並且也將安排 Keynote 演講與相關技術研討會，期望藉此會議提升國內在資訊安全相關領域研究水準及應用層面。同時，也將廣邀各公家政府機關與企業的經理人、技術人員甚至是資安高階人員等前來參加研討會議，透過此會議的召開，將可達到學術與實務應用之間的經驗交流，亦可使國內在資訊安全領域之專家學者們能夠更為了解現階段與資訊安全最新的發展趨勢。在會場中，也將另外設置廠商專區，主要為邀請資訊安全相關之廠商前來進行資安產品介紹及展示，更可讓與會人士了解目前業界中實際運作的方式，彼此相互交流達到互助互利。此外，前來參加論文發表的大專生、碩士生及博士生們也可透過此會議平台發表論文的經驗，藉此增加其學術見聞及交流，培養對於資訊安全領域的敏感度及研究興趣。</p>
            </div>
            <hr className="py-3"></hr>
        </div>
    )
}

export default About;