export default function Information(){
    return(
        <div className="container">
            <div className="w-100 p-5">
                <h1 className="text-center">會議資訊</h1>
            </div>
            <div className="pb-5">
                <p>本次會議建議以密碼學、5G安全、系統安全、數位鑑識及惡意程式分析、電子商務安全、網路與行動安全、隱私保護、入侵行為偵測等相關領域論文發表為主，舉凡和資訊安全相關議題皆在討論之列。</p>
                <p>會議內容建議分為下列幾個主軸：</p>
                <ul>
                    <li>主題演講（Keynote Speech）：</li>
                    <p>邀請國內知名學者以及產業專家，舉辦大會演講活動，提供與會者交流學習機會。為擴大各界專家學者參與全國資訊安全會議，我們擬辦理下列活動另為提升此次會議之國際化。</p>
                    <li>學術論文發表：</li>
                    <p>包含口頭發表和閃電講（含全文投稿及摘要投稿）</p>
                    <li>第三十四屆全國資訊安全會議 (CISC2024)設有最佳論文獎、最佳學生論文獎以及最佳簡報獎</li>
                    <ul className="py-3">
                        <li>最佳論文獎：本獎項由評審委員從全部投稿的論文中擇優評選之。此項論文獎評選的資格為該論文其中至少一位作者須為資訊安全學會會員。本研討會之最佳論文(英文論文)將規畫推薦收錄於國際知名期刊。</li>
                        <li>最佳學生論文獎：參加此項論文獎評選的資格為資訊安全學會之學生會員，須為該論文第一作者，並親自發表論文。評分重點包含論文品質與論文發表之表現。</li>
                        <li>最佳簡報獎：單一論文發表場次發表中，由場次會議主席（session chair）針對簡表進行評選。評分重點為論文發表之表現。</li>
                    </ul>
                    <div className="alert alert-info">
                        <p>— 主要聯繫資訊 —</p>
                        <ul>
                            <li>聯絡人：陳昱圻、孫勤昱</li>
                            <li>聯絡電話：(02) 27712171 #4231 #4245</li>
                            <li>聯絡地址：國立臺北科技大學資訊工程系（10608 台北市忠孝東路三段 1 號 北科大資工系）</li>
                        </ul>
                    </div>
                    <li>產學交流座談會</li>
                    <p>邀請國內官學研資訊安全代表中心或機構進行交流座談，同時舉行資安相關技術產品成果展、成果展示、技術說明或技術宣傳等，著重在產學合作交流之成果、人才培育及校園徵才活動。</p>
                    <li>資安廠商產品展示</li>
                    <p>邀請資安相關廠商展示產品，讓與會人士可以了解目前業界實際運作方式，同時開放廠商進行徵才活動。</p>
                </ul>
            </div>
        </div>
    )
}