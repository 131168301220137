export default function Partner(){
    return (
        <div className="container">
            <div className="w-100 p-5 pb-5">
                <h1 className="text-center">合作夥伴</h1>
                <div className="py-2 d-flex gap-5">
                    <div className="w-100 d-flex flex-column">
                        <a className="h-100 p-5 mx-auto d-flex flex-column justify-content-center" href="https://www.nstc.gov.tw/" target="_blank">
                            <div>
                                <img src="https://upload.wikimedia.org/wikipedia/zh/thumb/8/81/ROC_National_Science_and_Technology_Council.svg/300px-ROC_National_Science_and_Technology_Council.svg.png" />
                            </div>
                        </a>
                        <p className="py-2 text-center"> 國家科學及技術委員會 </p>
                    </div>
                    <div className="w-100 d-flex flex-column">
                        <a className="h-100 p-5 mx-auto d-flex flex-row justify-content-center" href="https://www.edu.tw/Default.aspx" target="_blank">
                            <img src="https://upload.wikimedia.org/wikipedia/zh/thumb/6/6d/ROC_Ministry_of_Education_Seal.svg/200px-ROC_Ministry_of_Education_Seal.svg.png" />
                        </a>
                        <div>
                            <p className="py-2 text-center"> 教育部 </p>
                        </div>
                    </div>
                </div>
                <h1 className="text-center">贊助廠商</h1>
                <div className="py-2 d-flex gap-5">
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3 gap-3" style={{height: "250px"}} href="https://www.onwardsecurity.com/" target="_blank">
                            <img className="w-100" src="./logo/onward-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 安華聯網科技 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.ciphertech.com.tw/" target="_blank">
                            <img className="w-100" src="./logo/ciphertech-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 亞利安科技股份有限公司 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.digicentre.com.tw/" target="_blank">
                            <img className="w-100" src="./logo/digicentre-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 果核數位股份有限公司 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.ttc.org.tw/" target="_blank">
                            <img className="w-100" src="./logo/ttc-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 財團法人電信技術中心 </p>
                    </div>
                </div>
                <div className="py-2 d-flex gap-5">
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.jcic.org.tw/main_ch/index.aspx" target="_blank">
                            <img className="w-100" src="./logo/jcic-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 財團法人金融聯合徵信中心 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.chtsecurity.com/" target="_blank">
                            <img className="w-100" src="./logo/chts-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 中華資安國際股份有限公司 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.twca.com.tw/" target="_blank">
                            <img className="w-100" src="./logo/twca-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 臺灣網路認證股份有限公司 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.nchc.org.tw/" target="_blank">
                            <img className="w-100" src="./logo/nchc-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 高速網路與計算中心 </p>
                    </div>
                </div>
                <div className="py-2 d-flex gap-5">
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.citi.sinica.edu.tw/" target="_blank">
                            <img className="w-100" src="./logo/citi-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 中央研究院資訊科技創新研究中心 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.trendmicro.com/zh_tw/business.html" target="_blank">
                            <img className="w-100" src="./logo/trend-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 趨勢科技股份有限公司 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.issdu.com.tw/" target="_blank">
                            <img className="w-100" src="./logo/issdu-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 數聯資安股份有限公司 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.provision.com.tw/" target="_blank">
                            <img className="w-100" src="./logo/provision-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 普鴻資訊股份有限公司 </p>
                    </div>
                </div>
                <div className="py-2 d-flex gap-5">
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://paysecure.com.tw/index.html" target="_blank">
                            <img className="w-100" src="./logo/paysecure-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 聯宏科技股份有限公司 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.easycard.com.tw/" target="_blank">
                            <img className="w-100" src="./logo/easycard-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 悠遊卡股份有限公司 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.raisetech.tw/" target="_blank">
                            <img className="w-100" src="./logo/rapixus-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 瑞思資訊股份有限公司 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://cycraft.com/" target="_blank">
                            <img className="w-100" src="./logo/cycraft-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 奧義智慧科技 </p>
                    </div>
                </div>
                <div className="py-2 d-flex gap-5">
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.104.com.tw/" target="_blank">
                            <img className="w-100" src="./logo/104-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 一零四資訊科技股份有限公司 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.facebook.com/ITRI.ICL/?locale=zh_TW" target="_blank">
                            <img className="w-100" src="./logo/itri-logo.jpg" />
                        </a>
                        <p className="py-2 text-center"> 財團法人工業技術研究院資通所 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.hwacom.com/" target="_blank">
                            <img className="w-100" src="./logo/hwacom-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 華電聯網股份有限公司 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.iii.org.tw/" target="_blank">
                            <img className="w-100" src="./logo/iii-logo.jpg" />
                        </a>
                        <p className="py-2 text-center"> 財團法人資訊工業策進會 </p>
                    </div>
                </div>
                <div className="py-2 d-flex gap-5">
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3 gap-3" style={{height: "250px"}} href="https://www.onwardsecurity.com/" target="_blank">
                            <img className="w-100" src="./logo/cht-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 中華電信股份有限公司電信研究院 </p>
                    </div> 
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://roboadvisor.com.tw/" target="_blank">
                            <img className="w-100" src="./logo/alpha-logo.png" />
                        </a>
                        <p className="py-2 text-center"> 阿爾發金融科技公司 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3 gap-3" style={{height: "250px"}} href="https://www.tca.org.tw/" target="_blank">
                            <img className="w-100" src="./logo/tca-logo.png" />
                            <img className="w-100" src="./logo/tca-ad.jpg" />
                        </a>
                        <p className="py-2 text-center"> 臺北市電腦商業同業公會 </p>
                    </div>
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.edit.com.tw/" target="_blank">
                            <img className="w-100" src="./logo/edit-logo.jpg" />
                        </a>
                        <p className="py-2 text-center"> 異術科技股份有限公司 </p>
                    </div>
                </div>
                <div className="py-2 d-flex gap-5">
                    <div className="d-flex flex-column" style={{width: "250px"}}>
                        <a className="w-100p-5 mx-auto d-flex flex-column justify-content-center p-3" style={{height: "250px"}} href="https://www.mas.org.tw/" target="_blank">
                            <img className="w-100" src="./logo/masa-logo.jpg" />
                        </a>
                        <p className="py-2 text-center"> 行動應用資安聯盟 </p>
                    </div>
                </div>
            </div>
        </div>
    )
}