export default function CampusMap(){
    return (
        <div className="container">
            <div className="w-100 p-5 pb-5">
                <h1 className="text-center">校園平面圖</h1>
            </div>
            <div>
                <img src="CampusMap.png" className="w-100"></img>
            </div>
        </div>
    )
}